import React from "react"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Hero = ({ herobg }) => {
  
  const image = convertToBgImage(getImage(herobg.childImageSharp.gatsbyImageData))

  return (
    <BackgroundImage
      {...image}
      className="hero-blog-container"
    >
      <div className="hero-blog-wrapper">
        <div className="hero-blog-content">
          <span className="our-blog">ClimateCheck 101</span>
          <h1 className="headline">Building a Safer Future</h1>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
