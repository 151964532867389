import React from "react"
import SEO from "@components/SEO"
import CorporativeHeader from "@components/global/CorporativeHeader"
import Hero from "@components/BlogPage/Hero"
import Blogs from "@components/BlogPage/Posts"
import CorporativeFooter from "@components/global/CorporativeFooter"
import { graphql } from "gatsby"
import "@styles/Components/blog.scss"
import RiskAssessment from "@components/global/RiskAssessment"
import Footer from "@components/global/Footer"

const BlogPage = ({
  data: { seo, allSanityPost, herobg, allSanityCategory },
}) => {
  const { edges } = allSanityPost
  const categories =
    allSanityCategory?.edges?.map(({ node }) => ({
      value: node?.title,
      label: node?.title,
    })) || []

  const customSeo = {
    title: seo?.seo?.title || "Blog",
    ...seo?.seo,
  }

  return (
    <>
      <SEO seo={customSeo}></SEO>
      <CorporativeHeader />
      <Hero herobg={herobg} />
      <Blogs posts={edges} categories={categories} />
      <RiskAssessment />
      <Footer />
    </>
  )
}

export default BlogPage

export const query = graphql`
  query MyQuery {
    seo: sanityPagesSeo(slug: { current: { eq: "/blog" } }) {
      seo {
        title
        keywords
        description
      }
    }
    herobg: file(relativePath: { eq: "img/Hero-Blog.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF] quality: 90)
      }
    }
    allSanityCategory {
      edges {
        node {
          title
        }
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          categories {
            title
          }
          customImage {
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF])
                description
              }
            }
          }
          customBlogImage {
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF])
                description
              }
            }
          }
          body {
            children {
              text
            }
          }
          author {
            name
          }
          publishedAt(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`
