import React, { useState } from "react"
import CardHazardPost from "./CardHazardPost"
import FilterCategories from "./FilterCategories"

const Blogs = ({ posts, categories }) => {
  const [_posts, setPosts] = useState(posts)
  const [activeCategory, setActiveCategory] = useState("all")

  const handleCategoryMenu = (category = 'all') => {
    setActiveCategory(category)
    if (category === 'all') {
      return setPosts(posts)
    }

    const filteredPosts = new Set([])
    posts.forEach(post => {
      post.node.categories.find(cat => {
        if (category === cat.title) {
          filteredPosts.add(post)
        }
      })
    })
    setPosts(Array.from(filteredPosts))
  }

  return (
    <div className="posts-container">
      <FilterCategories
        categories={categories}
        handleCategoryMenu={handleCategoryMenu}
        activeCategory={activeCategory}
      />
      <div className="posts-grid">
        {_posts.map((post, idx) =>  (
            <CardHazardPost
              key={idx}
              title={post.node.title}
              author={post.node.author.name}
              body={post.node.body}
              customImage={post.node.customImage}
              slug={post.node.slug.current}
              categories={post.node.categories}
              publishedAt={post.node.publishedAt}
              customCardImage={post.node.customBlogImage}
            />
          )
        )}
      </div>
    </div>
  )
}

export default Blogs
