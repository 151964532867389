import React from 'react'

export default function FilterCategories({categories, handleCategoryMenu, activeCategory}) {
  return (
    <div className="filter-post-container">
      <div className='items-container'>
        <span className="item" onClick={() => handleCategoryMenu('all')}>All</span>
        {categories.map((category, idx) => (<span key={idx} className={`item ${activeCategory === category.value ? `active-${activeCategory}` : ''} ${category.value}`} onClick={() => handleCategoryMenu(category.value)}>{category.value}</span>))}
      </div>
    </div>
  )
}
